var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-list-item', {
    staticClass: "font-secondary",
    attrs: {
      "text-align": "center",
      "display": ['none', 'flex'],
      "align-items": "center"
    }
  }, [_c('c-menu', {
    attrs: {
      "pos": "relative"
    }
  }, [_c('c-menu-button', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        ':hover': {
          backgroundColor: 'transparent'
        },
        ':focus': {
          boxShadow: 'none'
        }
      },
      expression: "{\n        ':hover': {\n          backgroundColor: 'transparent',\n        },\n        ':focus': {\n          boxShadow: 'none'\n        },\n      }"
    }],
    staticStyle: {
      "padding": "0",
      "height": "100%",
      "background": "transparent"
    },
    attrs: {
      "as": "span"
    }
  }, [_c('ButtonIcon', {
    staticClass: "p-1 h-full",
    staticStyle: {
      "height": "100%",
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "flex gap-4 items-center"
  }, [_c('p', {
    staticClass: "text-sm text-dgreen font-medium"
  }, [_vm._v(" " + _vm._s(_vm.fullName) + " ")]), _c('img', {
    staticClass: "w-14 h-14 rounded-full object-cover",
    attrs: {
      "src": _vm.getPhotoUser(_vm.user.photoUrl),
      "alt": _vm.fullName
    }
  })])])], 1), _c('c-menu-list', {
    attrs: {
      "rounded": "12px",
      "p": "0px"
    }
  }, [_c('c-menu-item', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        ':hover': {
          backgroundColor: 'transparent !important'
        },
        ':focus': {
          outline: 'none',
          boxShadow: 'none',
          background: 'none !important'
        }
      },
      expression: "{\n          ':hover': {\n            backgroundColor: 'transparent !important',\n          },\n          ':focus': {\n            outline: 'none',\n            boxShadow: 'none',\n            background: 'none !important',\n          },\n        }"
    }],
    attrs: {
      "p": "0px",
      "text-align": "center",
      "border-bottom": "1px solid #E5E5E5"
    }
  }, [_c('c-link', {
    attrs: {
      "px": "16px",
      "py": "15px",
      "display": "block",
      "w": "100%",
      "font-size": "18px",
      "color": "black.900",
      "as": "router-link",
      "to": {
        name: _vm.isClient ? 'client.profile.detail' : 'client.profile.account'
      }
    }
  }, [_vm._v(" Profile ")])], 1), _c('c-menu-item', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        ':hover': {
          backgroundColor: 'transparent !important'
        },
        ':focus': {
          outline: 'none',
          boxShadow: 'none',
          background: 'none !important'
        }
      },
      expression: "{\n          ':hover': {\n            backgroundColor: 'transparent !important',\n          },\n          ':focus': {\n            outline: 'none',\n            boxShadow: 'none',\n            background: 'none !important',\n          },\n        }"
    }],
    attrs: {
      "p": "0px"
    }
  }, [_c('c-box', {
    attrs: {
      "text-align": "center",
      "w": "100%",
      "h": "100%",
      "px": "16px",
      "py": "15px"
    },
    on: {
      "click": _vm.openModalConfirm
    }
  }, [_c('c-text', {
    attrs: {
      "display": "block",
      "w": "100%",
      "font-size": "18px",
      "color": "#FF0000"
    }
  }, [_vm._v(" Log Out ")])], 1)], 1)], 1)], 1), _c('ModalConfirmLogout', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isConfirm,
      expression: "isConfirm"
    }],
    attrs: {
      "is-open": _vm.isConfirm
    },
    on: {
      "handle-close": _vm.handleCloseConfirmModal,
      "logOut": _vm.logout
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }