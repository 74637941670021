<template>
  <c-menu
    v-slot="{ isOpen }"
    pos="relative"
    z-index="99"
  >
    <c-menu-button
      v-chakra="{
        ':hover': {
          backgroundColor: 'transparent',
        },
        ':focus': {
          boxShadow: 'none'
        },
      }"
      as="span"
      :is-active="isOpen"
      outline-offset="0"
      min-width="0"
      p="0"
      h="100%"
      bg="transparent"
    >
      <slot :has-unread-notification="hasUnreadNotification">
        <ButtonIcon>
          <BadgeUnread :show="hasUnreadNotification">
            <inline-svg
              :src="iconNotification"
              height="19"
              width="19"
              fill="#323232"
            />
          </BadgeUnread>
        </ButtonIcon>
      </slot>
    </c-menu-button>
    <c-menu-list 
      bg="transparent"
      p="0"
      box-shadow="none"
      border="none"
      font-size="0.875rem"
      line-height="1.25rem"
    >
      <c-box 
        bg="#FFF"
        border-radius="12px"
        w="100%"
        overflow="hidden"
        border="1px solid rgba(0,0,0,0.05)"
        box-shadow="0 4px 6px -1px rgba(0, 0, 0, 0.1)"
      >
        <c-box p="0.5rem">
          <c-text
            font-size="1rem"
            color="#008C81"
            text-align="center"
            font-weight="500"
          >
            Notifikasi
          </c-text>
        </c-box>
        <c-box
          as="hr"
          w="100%"
          border-top="1px solid #0000001A"
        />
        <NotificationItem
          style="max-height: 50vh; overflow-x: auto;"
          class="no-scrollbar"
          :notifications="notificationData"
          @onClick="handleClickNotification"
        />
        <c-box
          as="hr"
          w="100%"
          border-top="1px solid #0000001A"
        />
        <c-menu-item 
          v-chakra="{
            ':active': {
              bg: '#F2F2F2'
            } 
          }" 
          display="flex" 
          justify-content="center" 
          w="100%" 
          px="1rem" 
          pt="0.5rem"
          pb="1rem"
          bg="#FFF" 
          :font-size="['14px', '16px']"
          @click="handleReadAll"
        >
          Lihat semua
        </c-menu-item>
      </c-box>
    </c-menu-list>

    <ModalInfo
      for="infoNotitifcation"
      :close-on-overlay-click="false"
      :is-open="isOpenModal"
      :image="modalNotification.image"
      :text="modalNotification.text"
      :button-text="modalNotification.buttonText"
      :button-link="modalNotification.buttonLink"
      :with-button-close="modalNotification.buttonClose"
      @close="handleCloseSuccessModal"
    />
  </c-menu>
</template>

<script >
import ModalInfo from '@/components/widgets/modal-info/index.vue';
import iconNotification from '@/assets/icons/icon-notification.svg';
import ButtonIcon from './button-icon.vue';
import BadgeUnread from './badge-unread.vue';
import { useActions } from '@/stores';
import { useQuery } from '@tanstack/vue-query';
import { computed, ref, reactive } from '@vue/composition-api';
import { useRouter } from 'vue2-helpers/vue-router';
import NotificationItem from './notification-item.vue';
import { useNotification } from '@/components/header/composables/use-notification';
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const {
    handleReadNotification
  } = useNotification();
  const router = useRouter();
  const action = useActions('clients', ['loadNotifications', 'readNotification']);
  const fetchPopupNotification = useQuery({
    queryKey: ['useFetchPopupNotifications'],
    queryFn: async () => {
      const res = await action.loadNotifications();

      if (res) {
        return res;
      }

      return [];
    }
  });
  const notificationData = computed(() => {
    return fetchPopupNotification?.data?.value ? fetchPopupNotification?.data?.value.slice(0, 3) : [];
  });
  const hasUnreadNotification = computed(() => {
    return fetchPopupNotification?.data?.value?.some(v => v.isRead === 0);
  });

  const handleReadAll = () => {
    router.push({
      name: 'client.notification'
    });
  };

  const isOpenModal = ref(false);
  const modalNotification = reactive({
    image: '',
    text: '',
    buttonText: '',
    buttonLink: '',
    buttonClose: false
  });

  const handleClickNotification = notification => {
    if (notification['popupId'] || notification['popup.id']) {
      modalNotification.image = notification['popup.object'].image;
      modalNotification.text = notification['popup.object'].text;
      modalNotification.buttonText = notification['popup.object'].buttonText;
      modalNotification.buttonLink = notification['popup.object'].buttonLink;
      modalNotification.buttonClose = notification['popup.object'].buttonClose ? true : false;
      isOpenModal.value = true;
    }

    handleReadNotification(notification);
  };

  const handleCloseSuccessModal = () => {
    isOpenModal.value = false;
  };

  return {
    iconNotification,
    notificationData,
    hasUnreadNotification,
    handleReadAll,
    isOpenModal,
    modalNotification,
    handleClickNotification,
    handleCloseSuccessModal
  };
};

__sfc_main.components = Object.assign({
  ButtonIcon,
  BadgeUnread,
  NotificationItem,
  ModalInfo
}, __sfc_main.components);
export default __sfc_main;
</script>
