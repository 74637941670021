<template>
  <c-box width="100%">
    <c-box
      v-chakra="{
        ':hover': {
          backgroundColor: '#E2E8F0',
        },
      }"
      width="100%"
      as="button"
      display="flex"
      background-color="transparent"
      flex-direction="row"
      justify-content="space-between"
      w="100%"
      gap="2"
      text-align="center"
      h="auto"
      :border-top="['0.5px solid #C4C4C4', 'none']"
      :padding="['10px', '0']"
      border-radius="0"
      @click="redirect"
    >
      <c-text
        font-family="Roboto"
        :font-size="['14px', '16px']"
        line-height="18px"
        font-weight="400"
        :color="['#000000', '#008C81']"
      >
        Harga
      </c-text>
      <c-image
        :src="require('@/assets/icon-rupiah.svg')"
        mb="5px"
        :display="['block', 'none']"
      />
    </c-box>
  </c-box>
</template>

<script>
export default {
  name: 'HeaderPricing',
  props: {
    role: {
      type: String,
      default: '',
    },
  },
  methods: {
    redirect() {
      this.$emit('on-close')
      this.$router.push({
        name: 'client.pricing',
      })
    },
  },
}
</script>
