<template>
  <c-link
    :to="{name: 'auth', query: { tab: 'signup' }}"
    px="28px"
    py="8px"
    :color="['white', '#008C81']"
    :bg-color="['#008C81','white']"
    border-color="#008C81"
    border="2px"
    border-radius="100px"
    :font-size="['14px', '16px']"
    font-weight="500"
    font-family="Roboto"
    display="flex"
    w="100%"
    justify-content="center"
    flex-direction="row"
    gap="2"
    @click="handleClick"
  >
    Daftar
    <c-image
      :src="require('@/assets/icon-register.svg')"
      :display="['block','none']"
    />
    <c-image
      :src="require('@/assets/icon-register-desktop.svg')"
      :display="['none','block']"
    />
  </c-link>
</template>

<script>
export default {
  name: 'HeaderRegister',
  props: {
    role: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleClick() {
      this.$posthog.capture('visitor want to signup')

      this.$router.push({
        name: 'auth', query: {
          tab: 'signup',
        },
      })
    },
  },
}
</script>
