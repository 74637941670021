<template>
  <c-box
    as="footer"
    d="flex"
    flex-direction="column"
    align-items="center"
    pt="2rem"
    pb="1rem"
    bg="#008C8143"
    border-top-radius="1.5rem"
  >
    <c-flex
      data-testid="footer-dietela-icon"
      flex-direction="column"
      gap="0.5rem"
      mb="1rem"
    >
      <c-image
        src="https://ik.imagekit.io/dietela/pwa_webp/new_landing_page/logo-color@3x.webp?updatedAt=1689904664797"
        alt="dietela icon"
        h="45px"
        object-fit="contain"
        :mb="['0', '8px']"
      />
      <c-text
        color="#333"
        :font-size="['16px', '18px']"
        font-weight="500"
      >
        Dietela (PT. Gizi Sehat Indonesia)
      </c-text>
    </c-flex>

    <c-flex
      data-testid="footer-navigation"
      w="100%"
      justify-content="space-between"
      :px="['2rem', '10rem']"
    >
      <c-box
        data-testid="footer-navigation__left "
        :d="['block', 'none']"
      >
        <ItemNavigation :list-navigation="footer.slice(0, 2)" />
      </c-box>
      <c-box
        data-testid="footer-navigation__right "
        :d="['block', 'none']"
      >
        <ItemNavigation :list-navigation="footer.slice(2)" />
      </c-box>
      <c-box
        :d="['none', 'flex']"
        justify-content="space-between"
        w="100%"
      >
        <ItemNavigation :list-navigation="footer" />
      </c-box>
    </c-flex>

    <c-box
      data-testid="footer-social-media"
      d="flex"
      gap="1rem"
      my="1rem"
    >
      <a
        v-for="(socialMedia,index) in listFooterSocialMedia"
        :key="index"
        :href="socialMedia.url"
        target="_blank"
      >
        <inline-svg :src="socialMedia.iconSvg" />
      </a>
    </c-box>

    <c-box
      data-testid="footer-payment-method"
      d="flex"
      flex-direction="column"
      gap="1rem"
      align-items="center"
      :px="['1rem', '9rem']"
    >
      <c-text
        :font-size="['16px', '18px']"
        font-weight="500"
        color="#555"
      >
        Metode Pembayaran
      </c-text>
      <c-box
        d="flex"
        gap="1rem"
        flex-wrap="wrap"
        justify-content="center"
        align-items="center"
        bg="#FFF"
        py="1rem"
        :px="['0.5rem', '1rem']"
        border-radius="0.5rem"
      >
        <c-box
          v-for="(paymentMethod,index) in listFooterPaymentMethod"
          :key="index"
          :border="['0px solid #333333', 'none']"
          :border-radius="['8px', '0']"
          :p="['0px', '0']"
        >
          <c-image
            :src="paymentMethod.icon"
            :alt="paymentMethod.label"
            w="auto"
            :h="paymentMethod?.height ? paymentMethod?.height : ['17px' , '30px']"
          />
        </c-box>
      </c-box>
    </c-box>

    <c-box
      w="100%"
      my="1rem"
      border-top="1px solid #888888"
    />
    <c-text
      data-testid="footer-copyright"
      color="#008C81"
      :font-size="['12px', '14px']"
      :font-weight="['400', '500']"
    >
      Dietela (PT. Gizi Sehat Indonesia) © 2023 All right reserved
    </c-text>
  </c-box>
</template>

<script>
import { listFooterPaymentMethod, listFooterSocialMedia } from '@/constants/list-data-footer'
import ItemNavigation from './item-navigation.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'FootersLayout',
  components: {
    ItemNavigation,
  },
  data() {
    return {
      listFooterPaymentMethod,
      listFooterSocialMedia,
    }
  },
  computed: {
    ...mapGetters({
      footer: 'footer/data',
    }),
  },
  watch: {
    footer: {
      immediate: true,
      handler(val) {
        if (!val || val.length == 0) {
          this.getFooter()
        }
      },
    },
  },
  methods: {
    ...mapActions({
      getFooter: 'footer/getFooter',
    }),
  },
}
</script>