<template>
  <c-list-item
    text-align="center"
    :display="['none', 'flex']"
    align-items="center"
    class="font-secondary"
  >
    <c-menu pos="relative">
      <c-menu-button
        v-chakra="{
          ':hover': {
            backgroundColor: 'transparent',
          },
          ':focus': {
            boxShadow: 'none'
          },
        }"
        as="span"
        style="padding: 0; height: 100%; background: transparent;"
      >
        <ButtonIcon
          class="p-1 h-full"
          style="height: 100%; width: 100%;"
        >
          <div class="flex gap-4 items-center">
            <p class="text-sm text-dgreen font-medium">
              {{ fullName }}
            </p>
            <img
              :src="getPhotoUser(user.photoUrl)"
              :alt="fullName"
              class="w-14 h-14 rounded-full object-cover"
            >
          </div>
        </ButtonIcon>
      </c-menu-button>
      <c-menu-list
        rounded="12px"
        p="0px"
      >
        <c-menu-item
          v-chakra="{
            ':hover': {
              backgroundColor: 'transparent !important',
            },
            ':focus': {
              outline: 'none',
              boxShadow: 'none',
              background: 'none !important',
            },
          }"
          p="0px"
          text-align="center"
          border-bottom="1px solid #E5E5E5"
        >
          <c-link
            px="16px"
            py="15px"
            display="block"
            w="100%"
            font-size="18px"
            color="black.900"
            as="router-link"
            :to="{ name: isClient ? 'client.profile.detail' : 'client.profile.account' }"
          >
            Profile
          </c-link>
        </c-menu-item>
        <c-menu-item
          v-chakra="{
            ':hover': {
              backgroundColor: 'transparent !important',
            },
            ':focus': {
              outline: 'none',
              boxShadow: 'none',
              background: 'none !important',
            },
          }"
          p="0px"
        >
          <c-box
            text-align="center"
            w="100%"
            h="100%"
            px="16px"
            py="15px"
            @click="openModalConfirm"
          >
            <c-text
              display="block"
              w="100%"
              font-size="18px"
              color="#FF0000"
            >
              Log Out
            </c-text>
          </c-box>
        </c-menu-item>
      </c-menu-list>
    </c-menu>
    <ModalConfirmLogout
      v-show="isConfirm"
      :is-open="isConfirm"
      @handle-close="handleCloseConfirmModal"
      @logOut="logout"
    />
  </c-list-item>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import generalMixin from '@/utils/general-mixins'
import ButtonIcon from './elements/button-icon.vue'
import ModalConfirmLogout from '@/components/widgets/modal-confirm-logout.vue'

export default {
  name: 'HeaderUser',
  components: {
    ButtonIcon,
    ModalConfirmLogout,
  },
  mixins: [generalMixin],
  data() {
    return {
      isConfirm: false,
    }
  },
  computed: {
    ...mapState({
      user: (s) => s.auth.user,
      role: (s) => s.auth.user?.role,
    }),
    ...mapGetters({
      fullName: 'auth/fullName',
      paymentPage: 'general/paymentPage',
    }),
  },
  methods: {
    ...mapMutations({
      setPaymentPage: 'general/setPaymentPage',
    }),
    ...mapActions({
      signout: 'auth/signout',
    }),
    openModalConfirm() {
      if (this.paymentPage.isRouteGuardActive) {
        this.setPaymentPage({
          isOpenModalConfirmExitTransactionPage: true,
          customChangeFn: 'LOGOUT',
        })
        return
      }
      this.isConfirm = true
    },
    handleCloseConfirmModal() {
      this.isConfirm = false
    },
    logout() {
      this.signout()
        .then(() => {
          this.$store.dispatch('footer/getFooter')
          this.$store.commit('resetStore')
          this.$router.replace('/')
          if (this.$route.name.includes('admin')) {
            this.$router.replace({ name: 'auth', query: { tab: 'login' } })
          }
        })
        .catch(() => {
          this.$router.replace('/')
        })
    },
  },
}
</script>
