var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-link', {
    attrs: {
      "to": {
        name: 'auth',
        query: {
          tab: 'signup'
        }
      },
      "px": "28px",
      "py": "8px",
      "color": ['white', '#008C81'],
      "bg-color": ['#008C81', 'white'],
      "border-color": "#008C81",
      "border": "2px",
      "border-radius": "100px",
      "font-size": ['14px', '16px'],
      "font-weight": "500",
      "font-family": "Roboto",
      "display": "flex",
      "w": "100%",
      "justify-content": "center",
      "flex-direction": "row",
      "gap": "2"
    },
    on: {
      "click": _vm.handleClick
    }
  }, [_vm._v(" Daftar "), _c('c-image', {
    attrs: {
      "src": require('@/assets/icon-register.svg'),
      "display": ['block', 'none']
    }
  }), _c('c-image', {
    attrs: {
      "src": require('@/assets/icon-register-desktop.svg'),
      "display": ['none', 'block']
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }