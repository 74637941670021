import { render, staticRenderFns } from "./header-user.vue?vue&type=template&id=26234dbf&"
import script from "./header-user.vue?vue&type=script&lang=js&"
export * from "./header-user.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CMenuButton: require('@chakra-ui/vue').CMenuButton, CLink: require('@chakra-ui/vue').CLink, CMenuItem: require('@chakra-ui/vue').CMenuItem, CText: require('@chakra-ui/vue').CText, CBox: require('@chakra-ui/vue').CBox, CMenuList: require('@chakra-ui/vue').CMenuList, CMenu: require('@chakra-ui/vue').CMenu, CListItem: require('@chakra-ui/vue').CListItem})
