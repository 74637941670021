var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "as": "footer",
      "d": "flex",
      "flex-direction": "column",
      "align-items": "center",
      "pt": "2rem",
      "pb": "1rem",
      "bg": "#008C8143",
      "border-top-radius": "1.5rem"
    }
  }, [_c('c-flex', {
    attrs: {
      "data-testid": "footer-dietela-icon",
      "flex-direction": "column",
      "gap": "0.5rem",
      "mb": "1rem"
    }
  }, [_c('c-image', {
    attrs: {
      "src": "https://ik.imagekit.io/dietela/pwa_webp/new_landing_page/logo-color@3x.webp?updatedAt=1689904664797",
      "alt": "dietela icon",
      "h": "45px",
      "object-fit": "contain",
      "mb": ['0', '8px']
    }
  }), _c('c-text', {
    attrs: {
      "color": "#333",
      "font-size": ['16px', '18px'],
      "font-weight": "500"
    }
  }, [_vm._v(" Dietela (PT. Gizi Sehat Indonesia) ")])], 1), _c('c-flex', {
    attrs: {
      "data-testid": "footer-navigation",
      "w": "100%",
      "justify-content": "space-between",
      "px": ['2rem', '10rem']
    }
  }, [_c('c-box', {
    attrs: {
      "data-testid": "footer-navigation__left ",
      "d": ['block', 'none']
    }
  }, [_c('ItemNavigation', {
    attrs: {
      "list-navigation": _vm.footer.slice(0, 2)
    }
  })], 1), _c('c-box', {
    attrs: {
      "data-testid": "footer-navigation__right ",
      "d": ['block', 'none']
    }
  }, [_c('ItemNavigation', {
    attrs: {
      "list-navigation": _vm.footer.slice(2)
    }
  })], 1), _c('c-box', {
    attrs: {
      "d": ['none', 'flex'],
      "justify-content": "space-between",
      "w": "100%"
    }
  }, [_c('ItemNavigation', {
    attrs: {
      "list-navigation": _vm.footer
    }
  })], 1)], 1), _c('c-box', {
    attrs: {
      "data-testid": "footer-social-media",
      "d": "flex",
      "gap": "1rem",
      "my": "1rem"
    }
  }, _vm._l(_vm.listFooterSocialMedia, function (socialMedia, index) {
    return _c('a', {
      key: index,
      attrs: {
        "href": socialMedia.url,
        "target": "_blank"
      }
    }, [_c('inline-svg', {
      attrs: {
        "src": socialMedia.iconSvg
      }
    })], 1);
  }), 0), _c('c-box', {
    attrs: {
      "data-testid": "footer-payment-method",
      "d": "flex",
      "flex-direction": "column",
      "gap": "1rem",
      "align-items": "center",
      "px": ['1rem', '9rem']
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['16px', '18px'],
      "font-weight": "500",
      "color": "#555"
    }
  }, [_vm._v(" Metode Pembayaran ")]), _c('c-box', {
    attrs: {
      "d": "flex",
      "gap": "1rem",
      "flex-wrap": "wrap",
      "justify-content": "center",
      "align-items": "center",
      "bg": "#FFF",
      "py": "1rem",
      "px": ['0.5rem', '1rem'],
      "border-radius": "0.5rem"
    }
  }, _vm._l(_vm.listFooterPaymentMethod, function (paymentMethod, index) {
    return _c('c-box', {
      key: index,
      attrs: {
        "border": ['0px solid #333333', 'none'],
        "border-radius": ['8px', '0'],
        "p": ['0px', '0']
      }
    }, [_c('c-image', {
      attrs: {
        "src": paymentMethod.icon,
        "alt": paymentMethod.label,
        "w": "auto",
        "h": paymentMethod !== null && paymentMethod !== void 0 && paymentMethod.height ? paymentMethod === null || paymentMethod === void 0 ? void 0 : paymentMethod.height : ['17px', '30px']
      }
    })], 1);
  }), 1)], 1), _c('c-box', {
    attrs: {
      "w": "100%",
      "my": "1rem",
      "border-top": "1px solid #888888"
    }
  }), _c('c-text', {
    attrs: {
      "data-testid": "footer-copyright",
      "color": "#008C81",
      "font-size": ['12px', '14px'],
      "font-weight": ['400', '500']
    }
  }, [_vm._v(" Dietela (PT. Gizi Sehat Indonesia) © 2023 All right reserved ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }